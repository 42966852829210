.no-venue-games-top-bar {
	/*border-bottom: var(--defaultBorderColor) solid var(--defaultBorderColor);*/
	padding-bottom: var(--spacingSmallest);
}

.noVenueGamesContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	text-align: center;
}
