.header {
	border-bottom: 1px solid var(--defaultBorderColor);
	background: var(--colorWhite);
	position: fixed;
	width: 100%;
	z-index: 2;
}

.headerContainer {
	position: relative;
	display: flex;
	justify-content: space-between;
}

.logo {
	position: absolute;
	left: 0;
	top: calc(var(--logoSize)/-4);
	width: var(--logoSize);
	height: var(--logoSize);
}

.logo :global(img) {
	max-width: 100%;
}

.itemsRight {
	display: flex;
	justify-content: flex-end;
}

.menuContainer {
	margin-left: var(--logoSize);
	flex-grow: 1;
}

.userMenuContainer, .filterMenuContainer {
	display: flex;
	align-items: center;
}

.filterMenuContainer {
	margin-right: var(--spacing);
}
