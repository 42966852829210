.bookingDetailItem {
	display: flex;
	margin-bottom: var(--spacingSmallest);
}

.label {
	display: block;
	flex: 1;
	text-align: right;
	margin-right: var(--spacing);
}

.value {
	display: block;
	flex: 2;
}
