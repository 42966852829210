.booking-register-player-page{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.unable-to-edit-player-details-wrapper{
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.booking-register-player-form{
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 3rem;
}

.qr-code-modal-container{
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	justify-items: center;
}

.modal-qr-code-canvas{
	width: 400px !important;
	height: 400px !important;
}

.input-wrapper{
	display: flex;
	flex-direction: column;
}

.checkbox{
	width: fit-content;
}

.input{
	border-color: #92ccca;
	width: 100%;
}

.error{
	color: red;
}