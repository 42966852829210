.outstandingAmountForm :global(.ant-row) {
	display: flex;
}

.outstandingAmountForm :global(.ant-form-item-control-wrapper) {
	flex: 1;
}

.outstandingAmountForm :global(.ant-input-number) {
	width: 100%;
}
