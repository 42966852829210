.player-registration-container{
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding-bottom: 5rem;
}

.booking-registered-players-spinner{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.top-container{
	display: grid;
	grid-template-columns: auto auto;
	justify-content: space-between;
	column-gap: 2rem;
	width: 85%;
	margin-bottom: 4rem;
}

.text-container{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
}

.register-options-container{
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.qr-code-container{
	width: 11rem;
	height: 11rem;
}

.qr-code-canvas{
	width: 100%;
	height: 100%;
	border: 2px solid #000;
}

.register-players-button{
	width: 100%;
}

.title{
	font-size: 2.8rem;
	font-weight: 600;
	line-height: 1;
}

.team-leader-container{
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.team-leader-text{
	font-size: 2rem;
	margin-bottom: 0;
}

.team-leader-name{
	font-size: 1.6rem;
	margin-bottom: 0;
}

.booking-details-container{
	display: flex;
	justify-content: space-between;
}

.detail-item{
	display: flex;
	gap: 0.5rem;
}

.detail-item-text{
	margin: 0;
	line-height: 1;
	align-self: center;
}

.choose-profile-text{
	font-size: 1.25rem;
	margin-bottom: 0;
}

.registered-players-container{
	width: 85%;
	display: grid;
	row-gap: 5rem;
	grid-template-columns: repeat(3, auto);
	justify-content: space-between;
}


.add-player-avatar-container{
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 9rem;
	border: #239A98 1px solid;
	height: 9rem;
	width: 9rem;
	border-radius: 100%;
}

.add-player-avatar{
	position: relative;
	height: 9rem;
	width: 9rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 1rem;
}

.add-player-plus-icon{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.add-player-text{
	color: #239A98;
}

.avatar-container{
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 9rem;
}

.avatar-picture{
	height: 9rem;
	width: 9rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 1rem;
}

.avatar-name-container{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.avatar-name-bin-container{
	display: grid;
	grid-template-columns: 1fr auto;
	gap: 0.5rem;
	max-width: 100%;
}

.player-name{
	line-height: 1;
	margin-bottom: 0;
}

.bin-icon{
	line-height: 1;
	align-self: center;
}

.game-icon{
	line-height: 1;
	align-self: center;
}

.profile-icon{
	line-height: 1;
	align-self: center;
}

.time-icon{
	line-height: 1;
	align-self: center;
}