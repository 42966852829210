.venueContent {
	display: flex;
	flex-direction: column;
	align-content: space-between;
}

.venueItem {
	display: flex;
	justify-content: space-between;
	/*opacity: 0.65;*/
}
