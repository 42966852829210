.create-voucher-form-label {
	white-space: normal;
}

.create-voucher-form-editor {
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: all 0.3s;
	padding: 4px 11px;
	/*max-height: 250px;*/
	overflow: auto;
	resize: vertical;
	min-height: 300px;
}

.create-voucher-form-editor:focus, .create-voucher-form-editor:hover{
	border-color: #41a6a1;
	border-right-width: 1px !important;
	outline: 0;
	-webkit-box-shadow: 0 0 0 2px rgb(35 154 152 / 20%);
	box-shadow: 0 0 0 2px rgb(35 154 152 / 20%);
}

