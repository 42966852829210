.gsbcpe-root:not(:last-child) {
    margin-bottom: 24px;
}

.gsbcpe-booking-title {
    font-size: 16px;
}

.gsbcpe-profiles-container {
    display: grid;
    gap: 16px;
    margin-bottom: 16px;
}

.gsbcpe-booking-buttons-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

.gsbcpe-fullname {
    color: var(--colorGreyDarker);
}