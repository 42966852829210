.extraInfoLabel {
	font-size: var(--fontSizeSmallest);
}

.danger {
	color: var(--colorAlert);
}

.warning {
	color: var(--colorWarning);
}
