.container {
	display: flex;
}

.sidebar {
	width: var(--sideBarWidth);
	margin-right: var(--spacing);
	padding-top: var(--headerHeight);
}

.sidebarContainer {
	flex: 1;
}

.content {
	flex: 1;
	display: flex;
	flex-direction: column;
	width: calc(100% - var(--sideBarWidth) - var(--spacing));
}
