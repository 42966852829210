.no-rooms-top-bar {
	border-bottom: var(--defaultBorder) solid var(--defaultBorderColor);
	padding-bottom: var(--spacingSmallest);
}

.noRoomsContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	text-align: center;
}
