.game-card-row {
	display: flex;
	justify-content: space-between;
}

.game-card-label {
	font-weight: bold;
}

.game-card-content {
	text-align: right;
}
