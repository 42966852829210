/* Global css styles */

/* Global variables */
:root {
	--colorPrimaryLight: #BCFDE9;
	--colorPrimary: #73FBD3;
	--colorPrimaryDark: #73FBD3;

	--colorSecondaryLighter: #D2E9E8;
	--colorSecondaryLight: #239A98;
	--colorSecondary: #239A98;
	--colorSecondaryDark: #239A98;

	--colorInfo: #1FB6FF;
	--colorAlert: #FF4949;
	--colorSuccess: #13CE66;
	--colorWarning: #FFC82C;

	--colorGreyLight: #fafafa;
	--colorGrey: #e8e8e8;
	--colorGreyDarker: #767676;
	--colorWhite: #FFFFFF;
	--colorDark: #1a1a1a;

	--spacingSmallest: 4px;
	--spacingSmaller: 8px;
	--spacingSmall: 12px;
	--spacing: 16px;
	--spacingLarge: 32px;
	--spacingLarger: 48px;
	--spacingLargest: 64px;
	--spacingHuge: 75px;

	--borderRadius: 4px;
	--borderRadiusLarge: 8px;

	--borderSmall: 1px;

	--fontFamilySerif: Playfair Display, serif;
	--fontFamilySansSerif: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica Neue, Helvetica, Arial, sans-serif;
	--fontFamilyMonospace: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;

	--fontSizeSmallest: 10px;
	--fontSizeSmaller: 12px;
	--fontSizeSmall: 14px;
	--fontSize: 16px;
	--fontSizeLarge: 22px;
	--fontSizeLarger: 28px;
	--fontSizeLargest: 36px;
	--fontSizeHuge: 46px;

	--fontWeightThin: 100;
	--fontWeightLight: 200;
	--fontWeightRegular: 400;
	--fontWeightMedium: 500;
	--fontWeightBold: 600;
	--fontWeightBlack: 700;

	--defaultFontFamily: var(--fontFamilySansSerif);
	--defaultFontWeight: var(--fontWeightLight);
	--defaultFontSize: var(--fontSize);
	--defaultFontColor: var(--colorGreyDarker);
	--defaultLetterSpacing: 0px;
	--defaultLineHeight: 1.65;
	--defaultSpacing: var(--spacing);

	--defaultBorderColor: var(--colorGrey);
	--defaultBackgroundColor: var(--colorWhite);
	--defaultBorderRadius: var(--borderRadius);
	--defaultBorder: var(--borderSmall);

	--zIndexTopBar: 5;

	--maxWidth: 1040px;

	--logoSize: 100px;
	--headerHeight: 65px;
	--sideBarWidth: 200px;
	--sideBarHeight: 500px;

	--gamePlayerCardContainerPositionCircleRadius: 68px;
}

body {
	overflow-y: scroll;
}

svg {
	vertical-align: middle;
}

strong {
	font-weight: var(--fontWeightBold);
}

ul {
	list-style: none;
	padding: 0;
}

.max-width-container {
	max-width: var(--maxWidth);
	margin: 0 auto;
}

.max-width-container-with-padding {
	max-width: var(--maxWidth);
	margin: 0 auto;
	padding-left: var(--spacing);
	padding-right: var(--spacing);
}

.ant-btn-no-border, span.ant-btn-no-border button {
	border-color: transparent !important;
	box-shadow: none;
}

.ant-fullcalendar-month-select {
	width: var(--spacingHuge);
}

.smallerCells .ant-table-thead > tr > th,
.smallerCells .ant-table-tbody > tr > td {
	padding: var(--spacing) var(--spacingSmallest);
}

.margin-bottom {
	margin-bottom: var(--spacing);
}

.margin-top {
	margin-top: var(--spacing);
}

.no-margin {
	margin: 0;
}

.full-width {
	width: 100%;
}

.full-height {
	height: 100%;
}

:global(.anticon) {
	vertical-align: 0.125em;
}

.ant-col-24 {
	width: 100%;
}

.ant-form-horizontal .ant-form-item-control {
	flex: 1 1 auto;
}

.fixed {
	position: fixed;
}

.relative {
	position: relative;
}

.txt-upper {
	text-transform: uppercase;
}

:global(.ant-table-row) {
	cursor: pointer;
}

.auth-container {
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.auth-card-container {
	min-height: 600px;
}

.logo-container {
	padding: var(--spacingLarge);
	display: flex;
	justify-content: center;
	max-height: 14.5rem;
}

.txta-right {
	text-align: right;
}

.txta-center {
	text-align: center;
}

.flex {
	display: flex;
}

.flex-column {
	flex-direction: column;
}

.column {
	flex: 1;
}
.column-50 {
	flex: 1 0 50%;
}

.justify-content-space-between {
	justify-content: space-between;
}

.cursor-pointer {
	cursor: pointer;
}

/* PADDINGS */
.pb-8 {
	padding-bottom: 8px;
}
.pb-12 {
	padding-bottom: 12px;
}
.pb-16 {
	padding-bottom: 16px;
}

/* MARGINS */
.m-0 {
	margin: 0
}

/* Overvflows */
.overflow-y-hidden {
	overflow-y: hidden;
}

.overflow-y-scroll {
	overflow-y: scroll;
}

.overflow-y-auto {
	overflow-y: auto;
}