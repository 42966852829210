.gssp-main {
	padding-top: calc(var(--headerHeight) + 20px);
	display: grid;
	gap: 24px;
	padding-bottom: 48px;
}

.gssp-container-border {
	border-style: solid;
	border-color: var(--colorGrey);
	border-width: 1px;
}

.gssp-container-padding {
	padding: 24px;
}

.gssp-bottom-border {
	border-bottom-color: var(--colorGrey);
	border-bottom-style: solid;
	border-bottom-width: var(--defaultBorder);
}

.gssp-left-border {
	border-left-color: var(--colorGrey);
	border-left-style: solid;
	border-left-width: var(--defaultBorder);
}


.gssp-header {
	display: flex;
	gap: var(--spacing);
}

.gssp-players-customers-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	/*max-height: 600px;*/
	max-height: 70vh;
}

.gssp-column-title {
	font-size: 16px;
	font-weight: 600;
	font-family: var(--defaultFontFamily);
}

.gssp-players-container {
	display: grid;
	grid-template-columns: min-content auto min-content;
	grid-column-gap: var(--spacing);
	grid-row-gap: var(--spacing);
	align-items: center;
}

.gssp-buttons-container {
	display: flex;
	gap: 16px;
	flex-wrap: wrap;
}

.gssp-teams-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.gssp-save-container {
	display: grid;
	grid-template-columns: min-content auto;
	gap: 16px;
	align-items: center;
}
