.gsste-root {
    display: grid;
    grid-template-columns: min-content auto;
    gap: 24px;
}

.gsste-team-avatar-container {
    background-color: var(--colorGrey);
    width: 120px;
    height: 120px;
    cursor: pointer;
    position: relative;
}

.gsste-team-avatar-container:hover>.gsste-upload-place-holder {
    z-index: 3;
    background-color: var(--colorGreyDarker);
    /*background-color: var(--colorSecondary);*/
    color: white;
}
.gsste-team-avatar-container:hover>.gsste-upload-place-holder>.gsste-upload-icon>svg {
    fill: white;
}

.gsste-team-avatar-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
}

.gsste-upload-place-holder {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*color: var(--colorGreyDarker);*/
}

.gsste-upload-icon {
    margin-bottom: 16px;
}

.gsste-upload-icon>svg{
    width: 30px;
    height: 30px;
    fill: var(--colorGreyDarker);
}

.gsste-team-title {
    font-size: 16px;
    font-weight: 600;
    font-family: var(--defaultFontFamily);
}

.gsste-team-input-label {
    display: block;
    margin-bottom: 8px;
}