.voucher-details-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
}

.voucher-details-column {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.voucher-details-line {
	display: grid;
	grid-template-columns: 1fr 2fr;
}

.voucher-details-line-key {
	font-weight: 500;
}

.voucher-details-download-btn-container {
	/*padding-top: 12px*/
}

.voucher-details-divider {
	background: var(--colorGrey);
	height: 2px;
	grid-column-start: 1;
	grid-column-end: 3;
}

.voucher-details-comment {
	grid-column-start: 1;
	grid-column-end: 3;
}

.voucher-details-comment-content {
	background-color: var(--colorWhite);
	padding: 8px;
	border-radius: 5px;
	border-style: solid;
	border-width: 1px;
	border-color: var(--colorGrey);
}

.voucher-details-comment-content ul {
	list-style: disc;
	list-style-position: inside;
}

.voucher-details-comment-content em {
	font-style: italic;
}
