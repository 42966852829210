

.gsp-entry-number {
    font-size: var(--fontSize);
    color: var(--colorGreyDarker);
    opacity: 0.5;
    /*border: 1px solid var(--colorGrey);*/
    /*border-radius: 50%;*/
    /*width: var(--gamePlayerCardContainerPositionCircleRadius);*/
    /*height: var(--gamePlayerCardContainerPositionCircleRadius);*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
}

.gsp-entry-number-active {
    color: var(--colorSecondary);
    opacity: 1;
    font-weight: 600;
}

.gsp-btn-active {
    color: var(--colorSecondary);
    border-color: var(--colorSecondary);
}

.gsp-btn-disabled {
    color: var(--colorGreyLight);
    border-color: var(--colorGreyLight);
}

