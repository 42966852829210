.appUnavailableTitle{
	margin-top: var(--spacing);
}

.appUnavailable {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}
