.pricing-form-items-foot-note {
	font-size: var(--fontSizeSmaller);
	line-height: 1.2;
	margin-top: var(--spacingSmall);
}

.pricing-form-items-spacer {
	height: 2px;
	background: var(--colorGrey);
	margin-top: var(--spacing);
	margin-bottom: var(--spacing);
}

.pricing-form-items-entry {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 2fr;
	gap: 10px;
}

.pricing-form-voucher-alert-wrapper {
	padding-top: 16px;
}
