.leftItems {
	display: flex;
	justify-content: flex-start;
}

.rightItems {
	display: flex;
	justify-content: flex-end;
}

.searchContainer {
	width: 320px;
	margin-left: var(--spacing);
}

.clearContainer {
	margin-left: var(--spacing);
}
