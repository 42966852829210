.leftItems {
	display: flex;
	justify-content: flex-start;
}

.rightItems {
	display: flex;
	justify-content: flex-end;
}

.filterButtons {
	margin-right: var(--spacing);
}

.searchContainer {
	width: 300px;
}

.clearContainer {
	margin-left: var(--spacing);
}
