.requiredSection:after {
	display: inline-block;
	margin-left: 4px;
	content: "*";
	font-family: SimSun;
	line-height: 1;
	font-size: 14px;
	color: var(--colorAlert);
}

.hasError {
	color: var(--colorAlert);
}

.create-booking-form-container h3 {
	padding-bottom: var(--spacing);
	margin-bottom: 0;
	display: block;
}
