.buttonGroup {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	right: 0;
}

.booking-details-column {
	flex: 1 1;
}

.playerList {
	padding-left: 50px;
}

.priceUpdate {
	display: flex;
}

.priceUpdateInfo {
	margin-right: var(--spacing);
	flex: 2 1 0%;
}

.priceUpdateValue {
	flex: 1 1 0%;
	font-weight: var(--fontWeightBold);
}

.priceUpdateTooltip :global(.value) {
	flex-grow: 1;
}

